
  import {
    defineComponent,
    getCurrentInstance,
  } from "vue";
  import arraySort from "array-sort";
  import { IPagination, IHeaderConfiguration, ILayout } from '@/models/TableModel'
  
  export default defineComponent({
    name: "kt-datatable",
    emit: ["current-change", "sort", "items-per-page-change"],
    props: {
      tableHeader: {
        type: Array as () => Array<IHeaderConfiguration>,
        required: true,
      },
      tableData: { type: Array, required: true },
      emptyTableText: { type: String, default: "No data found" },
      loading: { type: Boolean, default: false },
      currentPage: { type: Number, default: 1 },
      total: { type: Number, default: 0 },
      rowsPerPage: { type: Number, default: 10 },
      order: { type: String, default: "asc" },
      sortLabel: { type: String, default: "" },
      layout: {
				default: () => {
					return {
						enableItemsPerPageDropdown: true,
						searching: true,
						hideOnSinglePage: false,
						info: true,
					} as ILayout
				} 
			},
			rowClass: {
				default: () => {
					return {}
				}
			}
    },
    data(){
			return {
				tableOrder: this.order,
				data: this.tableData,
				label: this.sortLabel,
				currentSort: "",
				pagination: {
					page: 1,
					total: this.total,
					rowsPerPage: this.rowsPerPage
				} as IPagination,
				search: "",
				vnodeProps: getCurrentInstance()?.vnode.props || {}
			}
    },
    watch: {
			'data.value': {
				handler(){
					if ("onCurrentChange" in this.vnodeProps) {
						this.currentSort = this.label + this.tableOrder;
					} else {
						this.pagination.total = this.tableData.length;
					}
				}
			}
    },
    mounted(){
			this.currentSort = this.label + this.tableOrder;
			this.pagination.total = this.total ? this.total : this.tableData.length;
			this.pagination.rowsPerPage = this.rowsPerPage;
    },
    computed: {
			filteredItems(){
				return this.tableData.filter((item: any) => {
					return Object.values(item).some((value: any) =>
						typeof value === "string" && value.toLowerCase().includes(this.search.toLowerCase())
					);
				});
			},
			getItems(){
				let items = this.filteredItems;
				if ("onCurrentChange" in this.vnodeProps) {
					return items as any
				} else {
					const clone = JSON.parse(JSON.stringify(items));
					const startFrom = this.pagination.page * this.pagination.rowsPerPage - this.pagination.rowsPerPage;
					return clone.splice(startFrom, this.pagination.rowsPerPage) as any;
				}
			},
			paginationInfo(){
				const pageStart = (1 + (this.pagination.page * this.pagination.rowsPerPage)) - this.pagination.rowsPerPage;
				const totalPerPage = (this.pagination.page * this.pagination.rowsPerPage);
				const total = this.pagination.total;

				return {
					start: pageStart,
					end: totalPerPage < total ? totalPerPage : total,
					total: total
				}
			}
    },
    emits: ['current-change', 'sort', 'items-per-page-change'],
    methods: {
			currentPageChange(val) {
				if ("onCurrentChange" in this.vnodeProps) {
					this.$emit("current-change", val);
				} else {
					this.pagination.page = val;
				}
			},
			sort (columnName, sortable) {
				if (sortable === false) {
					return;
				}

				if ("onSort" in this.vnodeProps) {
					if (this.tableOrder === "asc") {
						this.tableOrder = "desc";
						this.$emit("sort", { columnName: columnName, order: "desc" });
					} else {
						this.tableOrder = "asc";
						this.$emit("sort", { columnName: columnName, order: "asc" });
					}
				} else {
					if (this.tableOrder === "asc") {
						this.tableOrder = "desc";
						arraySort(this.tableData, columnName, { reverse: false });
					} else {
						this.tableOrder = "asc";
						arraySort(this.tableData, columnName, { reverse: true });
					}
				}

				this.currentSort = columnName + this.tableOrder;
			},
			setItemsPerPage(event) {
				if ("onItemsPerPageChange" in this.vnodeProps) {
					this.$emit("items-per-page-change", parseInt(event.target.value));
				} else {
					this.pagination.rowsPerPage = parseInt(event.target.value);
				}
			}
    }
  });
